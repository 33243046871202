<template>
  <div>
    <h3 class="flix-html-h3">{{ $tc('message.appointmentOverview') }}</h3>
    <calendarBreadcrumbs :link="'dashboardDownloadCenter'" />
    <transition name="flixFadeIn">
      <div class="flix-form-group" v-if="$route.params && $route.params.id && $route.params.id !== 'all'" :key="$route.params.id">
        <div v-if="$store.getters.bookings[$route.params.id] && $store.getters.bookings[$route.params.id].length">
          <div class="flix-input-group flix-form-group">
            <span class="flix-input-group-addon">
              <select v-model="filterCSV">
                <option value="">&infin; {{ $tc('message.appointments', 2) }}</option>
                <option value="yesterday">{{ $tc('message.appointments', 2) }} {{ $t('message.yesterday') }}</option>
                <option value="today">{{ $tc('message.appointments', 2) }} {{ $t('message.today') }}</option>
                <option value="tomorrow">{{ $tc('message.appointments', 2) }} {{ $t('message.tomorrow') }}</option>
                <option value="+1week">{{ $tc('message.appointments', 2) }} + 1 {{ this.$tc('message.weeks', 1) }}</option>
              </select>
            </span>
            <csvDownload :filter="filterCSV" :className='{"flix-btn flix-btn-default flix-btn-block": true}' :form="$route.params.id"/>
          </div>
          <div class="flix-input-group flix-form-group">
            <span class="flix-input-group-addon">
              <select v-model="filterCSV">
                <option value="">&infin; {{ $tc('message.appointments', 2) }}</option>
                <option value="yesterday">{{ $tc('message.appointments', 2) }} {{ $t('message.yesterday') }}</option>
                <option value="today">{{ $tc('message.appointments', 2) }} {{ $t('message.today') }}</option>
                <option value="tomorrow">{{ $tc('message.appointments', 2) }} {{ $t('message.tomorrow') }}</option>
                <option value="+1week">{{ $tc('message.appointments', 2) }} + 1 {{ this.$tc('message.weeks', 1) }}</option>
              </select>
            </span>
            <pdfDownload :filter="filterCSV" :className='{"flix-btn flix-btn-default flix-btn-block": true}' :entry="$store.getters.bookings[$route.params.id]">{{ $t('message.pdfExport') }}</pdfDownload>
          </div>
        </div>
        <div class="flix-alert flix-alert-danger" v-else>
          {{ $tc('message.noEntry', 2) }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    csvDownload () { return import('@/components/csv') },
    pdfDownload () { return import('@/components/crm/pdf_download') }
  },
  props: {},
  data () {
    return {
      filterCSV: ''
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
